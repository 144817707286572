.classes {
  background-color: #7fffd485;
  border: 1px solid darkgreen;
  border-radius: 12px;
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.classes .hdrMT {
  margin-top: 2.5rem;
}

.classes h2 { 
  text-align: center;
  font-size: 1.2rem;
}

.classes p { 
  font-weight: 500;
  font-size: 1rem; 
}

.classes label {
  font-weight: 500;
  font-size: 1rem;
}

.classes .floatRight {
  float: right;
  margin: 0.25rem 0.5rem;
}

.classes .inputView {
  background-color: 'whitesmoke';
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-radius: 8;
  border-width: 4;
  border-color: '#d6adad';
  margin-bottom:0.5rem;
  margin-right: 0.5rem;
}

input {
  width: 16rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.5rem 0;
  padding: 0.5rem 0.25rem;
  background-color: #ffff00de;
}

.classes .btnDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;
  margin-bottom: 1.4rem;
}

.classes button {
  width: 8rem;
  font-size: 1rem;
  border-radius: 12px;
  color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  padding: 0.25rem 0.75rem;
}

.classes .btnTBMargins {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.classes .bgBlue {
  background-color: blue;
  border: 1px solid blue;
}

.classes .bgGreen {
  background-color: green;
  border: 1px solid green;
}

.classes .bgPurple {
  background-color: #cc0099;
  border: 1px solid #cc0099;
}

.classes .bgRed {
  background-color: red;
  border: 1px solid red;
}

.classes .bgBlack {
  background-color: black;
  border: 1px solid black;
}

.classes button:focus {
  outline: none;
}

.classes button:hover {
  color: black;
  background: gold;
  border-color: gold;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}

.classes ol {
  list-style-type: lower-alpha;
  margin: 0.75rem;
  padding: 0.25rem 0 0 0.75rem;
  line-height: 2.5rem;
  font-size: 1rem;
}

.classes .errorText {
  display: block;
  margin: 0 0 0.5rem 0;
  padding: 0 0.25rem;
  color: red;
}

.classes textarea {
  display: block;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 1.5rem;
  margin: 1rem 0;
  background-color: #ffff00de;
}

.classes textarea:focus {
  outline: none;
  background: #fad0ec;
  border-color: #8b005d;
}
/*
@media (min-width: 1025px) {
  .classes {
    background-color: #7fffd485;
    border: 1px solid darkgreen;
    border-radius: 12px;
    width: 1024px;
    margin: 0.25rem auto;
    padding: 0.25rem 0.5rem;
  }  
} 
*/