.classes {
    background-color: #7fffd485;
    border: 1px solid darkgreen;
    border-radius: 12px;
    margin: 0.25rem 0;
    padding: 0.25rem 0.5rem;
    height: auto;
}

.classes h2 { 
  text-align: center;
  color: red;
  font-size: 1.2rem;
}

.classes p {
    display: block;
    line-height: 2.4rem;
    margin: 0.5rem 0;
    padding: 0 0.25rem;
    font-size: 1rem;
}

.classes .btnDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.2rem;
  margin-bottom: 1.4rem;
}

.classes button {
  width: 8rem;/*35%*/
  font-size: 1rem;
  border-radius: 12px;
  color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;  
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

.classes .bgRed {
  background-color: red;
  border: 1px solid red;
}

/*
@media (min-width: 1025px) {
  .classes {
    background-color: #7fffd485;
    border: 1px solid darkgreen;
    border-radius: 12px;
    width: 1024px;
    margin: 0.25rem auto;
    padding: 0.25rem 0.5rem;
  }  
}
*/