.classes {
  background-color: #7fffd485;
  border: 1px solid darkgreen;
  border-radius: 12px;
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.classes h2 { 
  text-align: center;
  font-size: 1.2rem;
}

.classes p { 
  font-weight: 500;
  font-size: 1rem; 
}

.classes label {
  font-weight: 500;
}

.classes .floatRight {
  float: right;
  margin: 0.25rem 0.5rem;
}

.classes .inputView {
  background-color: 'whitesmoke';
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-radius: 8;
  border-width: 4;
  border-color: '#d6adad';
  margin-bottom:0.5rem;
  margin-right: 0.5rem;
}

.classes input {
  width: 16rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  line-height: inherit;
  margin: 0.5rem 0;
  padding: 0.5rem 0.25rem;
  background-color: #ffff00de;
}

.classes .btnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: .75rem;
  margin-bottom: 0.5rem;
}

.classes button {
  width: 8rem;
  font-size: 1rem;
  border-radius: 12px;
  color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;  
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  padding: 0.25rem 0.75rem;
}

.classes .bgBlue {
  background-color: blue;
  border: 1px solid blue;
}

.classes .bgGreen {
  background-color: green;
  border: 1px solid green;
}

.classes .bgPurple {
  background-color: #cc0099;
  border: 1px solid #cc0099;
}

.classes .bgRed {
  background-color: red;
  border: 1px solid red;
}

.classes .bgBlack {
  background-color: black;
  border: 1px solid black;
}

.classes button:focus {
  outline: none;
}

.classes button:hover {
  color: black;
  background: gold;
  border-color: gold;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}

.classes .errorText {
  display: block;
  padding: 0 0.25rem;
  color: red;
}

/* 
for small screens, add media querry
@media (min-width: 768px) {
  .button {
    width: auto;
  }
} 
*/