.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 2.5vh;
  left: 2.5vw;
  width: 95vw;
  height: 95vh;
  overflow-y: scroll;
  background-color: bisque;
  padding: 0.1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 1025px) {
  .backdrop {
    position: fixed;
    width: 1024px;
    height: 100vh;
    top: 0;
    left: calc(50vw - 512px);
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .modal {
    position: fixed;
    width: 920px;
    height: 90vh;
    top: 2rem;
    left: calc(50vw - 460px);
    overflow-y: scroll;
    background-color: bisque;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
  }
} 

/*
What is wrong with my css code
I am trying to position the modal at the center of the screen so that both right and left space will be the same

@media (min-width: 1025px) {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 1024px;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .modal {
    position: fixed;
    width: 920px;
    height: 90vh;
    top: 2rem;
    left: calc((100vw - 920px)*0.5);// This is not working
    overflow-y: scroll;
    background-color: bisque;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
  }
} 
*/