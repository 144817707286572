.classes {
  background-color: #7fffd485;
  border: 1px solid darkgreen;
  border-radius: 12px;
  margin: 0;
  padding: 0.25rem;
}

.classes header {
  margin-top: 0.5rem;
}

.classes h2 { 
  text-align: center;
  font-size: 1.2rem; 
}

.classes p { 
  font-weight: 500;
  font-size: 1rem; 
  text-align: center;
}

.classes .btnDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.classes .btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80vw;
  align-items: center;
  margin-bottom: 0.5rem;
}

.classes button {
  width: 12rem;
  font-size: 1rem;
  border-radius: 12px;
  color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
}

.classes .bgBlue {
  background-color: blue;
  border: 1px solid blue;
}

.classes .bgGreen {
  background-color: green;
  border: 1px solid green;
}

.classes .bgDarkGreen {
  background-color: darkgreen;
  border: 1px solid darkgreen;
}

.classes .bgDarkGold {
  background-color: darkgoldenrod;
  border: 1px solid darkgoldenrod;
}

.classes .bgDarkPurple {
  background-color: #8b005d;
  border: 1px solid #8b005d;
}

.classes .bgPurple {
  background-color: #ff00bf;
  border: 1px solid #ff00bf;
}

.classes .bgRed {
  background-color: red;
  border: 1px solid red;
}

.classes .bgBlack {
  background-color: black;
  border: 1px solid black;
}

.classes button:focus {
    outline: none;
}

.classes button:hover {
    color: black;
    background: gold;
    border-color: gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}
