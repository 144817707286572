.classes {
    background-color: #7fffd485;
    border: 1px solid darkgreen;
    border-radius: 12px;
    margin: 0;
    padding: 0.25rem;
}

.classes h2 { 
    text-align: center;
    font-size: 1.2rem; 
}

.classes p {
    display: block;
    line-height: 2.4rem;
    margin: 0.5rem 0;
    padding: 0 0.25rem;
    font-size: 1rem;
}

.classes .btnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.classes button {
    font: inherit;
    font-size: 1rem;
    border: 1px solid #8b005d;
    border-radius: 6px;
    color: white;
    background: #8b005d;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    margin: 0.5rem 0.25rem 1.5rem 0;
    padding: 0.5rem 1.5rem;
}

.classes a {
    margin: 0 2rem;
}

.classes i.fa-facebook { color: #3b5998; }
.classes i.fa-twitter { color: #1da1f2; }
.classes i.fa-whatsapp { color: #25d366; }

.classes button:focus { outline: none; }

.classes button:hover {
    color: black;
    background: gold;
    border-color: gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}

@media (min-width: 1025px) {
  
} 