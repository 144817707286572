.classes {
  background-color: #7fffd485;
  border: 1px solid darkgreen;
  border-radius: 12px;
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.classes .hdrMT {
  margin-top: 2.5rem;
}

.classes .floatRight {
    float: right;
}

.classes h2 { 
  text-align: center;
  font-size: 1.2rem;
}

.classes p { 
  font-weight: 500;
  font-size: 1rem; 
  margin-bottom: 2rem;
}

.classes .btnDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1.2rem;
    margin-bottom: 1.4rem;
}

.classes button {
  width: 6rem;
  font-size: 1rem;
  border-radius: 12px;
  color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
}

.classes .btnTBMargins {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.classes .bgBlue {
  background-color: blue;
  border: 1px solid blue;
}

.classes .bgGreen {
  background-color: green;
  border: 1px solid green;
}

.classes .bgDarkPurple {
  background-color: purple;
  border: 1px solid purple;
}

.classes .bgPurple {
  background-color: #ff00bf;
  border: 1px solid #ff00bf;
}

.classes .bgRed {
  background-color: red;
  border: 1px solid red;
}

.classes .bgBlack {
  background-color: black;
  border: 1px solid black;
}

.classes button:hover {
    color: black;
    background: gold;
    border-color: gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}

.classes ol {
  list-style-type: lower-alpha;
  margin: 0.75rem;
  padding: 0.25rem 0 0 0.75rem;
  line-height: 2.5rem;
  font-size: 1rem;
}

.classes .errorText {
  display: block;
  margin: 0 0 0.5rem 0;
  padding: 0 0.25rem;
  color: red;
}

.classes textarea {
  display: block;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font: inherit;
  line-height: 1.5rem;
  padding: 0.5rem 1.5rem;
  margin: 1rem 0;
  background-color: #ffff00de;
}

.classes textarea:focus {
  outline: none;
  background: #fad0ec;
  border-color: #8b005d;
}

.classes select {
  display: block;
  border: 1px solid #ccc;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  margin: 0.5rem 0;
  padding: 0.5rem 0.25rem;
  background-color: #ffff00de;
  width: 25rem;
}

@media (max-width: 599px) {
  .classes textarea {
    display: block;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    line-height: 1.5rem;
    padding: 0.75rem 0.75rem;
    margin: 1rem 0;
    background-color: #ffff00de;
    width: 20rem;
  }

  .classes select {
    display: block;
    border: 1px solid #ccc;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    margin: 0.5rem 0;
    padding: 0.5rem 0.25rem;
    background-color: #ffff00de;
    width: 20rem;
  }
} 