.classes {
    background-color: whitesmoke;
    border: 1px solid black;
    border-radius: 12px;
    margin: 0;
    padding: 0.25rem;
}

.classes h2 { 
    text-align: center;
    font-size: 1.2rem; 
}

.classes h3{
    font-size: 1rem;
}

.classes p { 
    font-size: 1rem; 
}

.classes .btnDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.classes button {
    font: inherit;
    font-size: 1rem;
    border-radius: 12px;
    color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    margin: 0.5rem 1rem;
    padding: 0.5rem 1.5rem;
}

.classes .bgGreen {
    background-color: green;
    border: 1px solid green;
}

.classes .bgRed {
    background-color: red;
    border: 1px solid red;
}

.classes button:focus {
    outline: none;
}

.classes button:hover {
    color: black;
    background: gold;
    border-color: gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}