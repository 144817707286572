.classes {
  background-color: #7fffd485;
  border: 1px solid darkgreen;
  border-radius: 12px;
  margin: 0;
  padding: 0.25rem;
}

.classes h2 {
  font-size: 1.2rem;
}

.classes label {
  display: block;
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
  font-weight: bold;
}

.classes input {
  display: inline-block;
  width: 15rem;
  font-weight: 700;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font: inherit;
  line-height: inherit;
  margin: 0.5rem 0;
  padding: 0.5rem 0.25rem;
  background-color: #ffff00de;
}

.classes .errorText {
  display: block;
  margin: 0 0 0.5rem 0;
  padding: 0 0.25rem;
  color: red;
  font-size: 1rem;
}

.classes .btnDiv {
  margin-top: 1.2rem;
  margin-bottom: 1.4rem;
}

.classes button {
  font: inherit;
  font-size: 1rem;
  border: 1px solid #8b005d;
  border-radius: 12px;
  color: white;
  background: #8b005d;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin: 0 0.75rem 0 0;
  padding: 0.5rem 1.5rem;
}

.classes button:focus {
  outline: none;
}

.classes button:hover {
    color: black;
    background: gold;
    border-color: gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}

.classes .bgGreen {
    background-color: green;
    border: 1px solid green;
}

.classes .bgRed {
    background-color: red;
    border: 1px solid red;
}
