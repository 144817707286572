.classes {
    position: relative;
    top: -1rem;
}

.classes p {
    display: block;
    line-height: 2.4rem;
    margin: 0.5rem 0;
    padding: 0 0.25rem;
    font-size: 1rem;
}

.classes a {
    margin: 0 2rem;
}

.classes i.fa-facebook { color: #3b5998; }
.classes i.fa-twitter { color: #1da1f2; }
.classes i.fa-whatsapp { color: #25d366; }

.classes a:focus { outline: none; }

.classes a:hover {
    color: black;
    background: gold;
    border-color: gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}