@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0 auto;
  background-color: #3f3f3f14;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-color: #0b6600;
  padding: 20px;
  color: white;
  text-align: center;
  flex-wrap: wrap;
}

p {
  line-height: 2.4;
}

.red-underline {
  display: inline-block;
  text-decoration: underline solid red 2px;
}

/* add these for test papers*/
.testDocHeader {
  color: black;
  border-radius: 8px;
}

.testDocH2 {
  text-align: center;
  font-size: 1.2rem;
}

.question-note {
  margin: 0.5rem;
}
.qae {
  border-bottom: 3px solid red;
}

.testDocSn,
.question_nu { 
  font-weight: 500;
  font-size: 1rem; 
  margin: 0;
  padding: 0;
}

.questions p,
.answers p {
  font-size: 1rem; 
  margin: 0;
  padding: 0
}

.testDocBtnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0.25rem 0;
}

.flag {
  background-color: blue;
  border: 1px solid blue;
}

.explain {  
  background-color: green;
  border: 1px solid green; 
}

.bgPurple {
  background-color: #cc0099;
  border: 1px solid #cc0099;
}

.bgRed {
  background-color: red;
  border: 1px solid red;
}

.bgBlack {
  background-color: black;
  border: 1px solid black;
}

.testDocBtn:focus {
  outline: none;
}

.testDocBtn:hover {
  color: black;
  background: gold;
  border-color: gold;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}

.questions-ol,
.answers-ol {  
  list-style-type: lower-alpha;
  line-height: 2.4;
  font-size: 1rem;
  margin-top: 0;
}

.notes-ol {  
  list-style-type: upper-alpha;
  line-height: 2.4;
  font-size: 1rem;
  margin-top: 0;
}

.obj-sub-answers {
  list-style-type: lower-roman;
  margin-left: 1cqb;
  padding: 0.25rem;
  line-height: 2.4;
  font-size: 1rem;
}

.sub-questions, 
.sub-answers,
.sub-notes-ol {
  list-style-type: lower-roman;
  margin-left: 0.3rem;
  padding: 0.25rem;
  line-height: 2.4;
  font-size: 1rem;
}

.subSub-questions,
.subSub-answers {
  list-style-type: decimal-leading-zero;
  margin-left: 1.25rem;
  padding: 0.25rem;
  line-height: 2.4;
  font-size: 1rem;
}

.answerOption {
  width: 25px;
  float: right; 
  font-weight: 900; 
  font-size: 1rem
}

.testDocTextarea {
  display: block;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font: inherit;
  line-height: 2.4;
  padding: 0.5rem 1.5rem;
  margin: 1rem 0;
  background-color: #ffff00de;
}

.testDocTextarea:focus {
  outline: none;
  background: #fad0ec;
  border-color: #8b005d;
}

.imgDisplay {
  display: block; 
  margin: auto; 
}

.testDocImg {
  display: block; 
  width: 300px;
  height: 300px;
  text-align: center;
  margin: 0.5rem auto;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.testDocImage {
  display: block;
  width: 300px;
  text-align: center;
  margin: 0.5rem auto;
  border-radius: 8px;
  border: 1px solid #ddd;
}

table {
    border-collapse: collapse;
}

.fit-contentViewTbl {
  width: fit-content;
}

table caption {
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
}

table th {
  font-weight: 700;
  font-size: 1rem;
  border: 1px solid #666666;
  padding: 0.5rem;
  text-align: center;
  vertical-align: text-top;
}

tbody {
  background-color: inherit;
}

 tbody td,
 tfoot td {
  border: 1px solid #666666;
  padding: 0.25rem;
  font-size: 1rem;
  vertical-align: text-top;
}

tfoot td {
  font-weight: 900;
}

.rightBorderTd {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid;
  padding: 0.25rem;
  font-size: 1rem;
  vertical-align: text-top;
}

.borderlessTd {
  border: none;
}

/* Below for √ */
.sqrt-symbol {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.sqrt-bar {
  background-color: black;
}

@media only screen and (min-width: 360px) and (max-width: 599px) {
  .main-header-img {
    width: 60px;
    height: auto;
  }

  h1 {
    font-size: 0.6rem;
    margin: 0;
  }

  .testDocH2 {
    text-align: center;
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
  h1 {
    font-size: 1.3rem;
    margin: 0;
  }

  .main-header-img {
    width: 75px;
    height: auto;
  }

  .testDocH2 {
    text-align: center;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  h1 {
    font-size: 1.8rem;
    margin: 0;
  }

  .main-header-img {
    width: 100px;
    height: auto;
  }

  .testDocH2 {
    text-align: center;
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  body {
    width: 1024px;    
  } 

  h1 {
    font-size: 2.2rem;
    margin: 0;
  }

  .main-header-img {
    width: 125px;
    height: auto;
  }
}

@media print {
  body {
    width: 100vw;    
  } 

  h1 {
    font-size: 1.8rem;
    margin: 0;
  }

  .main-header-img {
    width: 100px;
    height: auto;
  }

  .main-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: #0b6600;
    padding: 20px;
    color: white;
    text-align: center;
    flex-wrap: wrap;
  }

  button {
    display: none !important;
  }
}