.classes {
    background-color: #7fffd485;
    border: 1px solid darkgreen;
    border-radius: 12px;
    margin: 0;
    padding: 0.25rem;
}

.classes h2 { 
    text-align: center;
    font-size: 1.2rem; 
}

.classes .btnDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;
    margin-bottom: 1.4rem;
}

.classes button {
    font: inherit;
    font-size: 1rem;
    border-radius: 12px;
    color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0.5rem 1.5rem;
    width: 12rem;/*35%*/
}

.classes .bgBlue {
    background-color: blue;
    border: 1px solid blue;
}

.classes .bgGreen {
    background-color: green;
    border: 1px solid green;
}

.classes .bgRed {
    background-color: red;
    border: 1px solid red;
}

.classes .bgBlack {
    background-color: black;
    border: 1px solid black;
}

.classes .bgPurple {
    background-color: #8b005d;
    border: 1px solid #8b005d;
}

.classes button:focus {
    outline: none;
}

.classes button:hover {
    color: black;
    background: gold;
    border-color: gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
}
