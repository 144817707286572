.checkbox {
    display: inline-block;
    width: 1.2rem;/*20px;/*auto*/
    height: 1.2rem;/*20px;*/
    margin-left: 0.5rem;
}
  
.checkbox label {
    font-weight: 700;
    /*width: auto;*/
}

.checkbox input {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    font: 1rem;
    padding: 0 0.25rem;
}
  
.checkbox input:focus {
    outline: none;
    background: #fad0ec;
    border-color: #8b005d;
}